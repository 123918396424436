import { GA_TRACKING_ID } from '../constants';

export enum GAEvents {
  startProjectClick = 'Start_Project_BTN_Click',
  bookACallClick = 'Book_a_Call_BTN_Click',
  linkedInClick = 'Linkedin_BTN_Click',
  startNowClick = 'Start_now_BTN_Click',
  boostProjectClick = 'Boost_your_project_BTN_Click',
  becomeClientClick = 'Become_Client_BTN_Click',
  letsWorkTogetherClick = 'Lets_work_together_BTN_Click',
  firstBlockClick = 'First_block_BTN_Click',
  letsCollaborateClick = 'Lets_Collaborate_BTN_Click',
  joinUsClick = 'Join_us_BTN_Click',
  startYourProjectClick = 'start_your_project_BTN_Click',
  applyFormClick = 'Apply_form_BTN_Click',
  subscribeClick = 'Subscribe_BTN_Click',

  contactFormSubmission = 'Form_Submission_Popup',
  consultationFormSubmission = 'Form_Submission_Free_Consultation',
  joinTeamFormSubmission = 'Form_Submission_Join our_team',
  learningFormSubmission = 'Form_Submission_Learning_Opportunities',
}

export const GAEmit = (eventName: GAEvents) => {
  const params = {
    page_url: window.location.href,
  };

  gtag('event', eventName, params);
};

export const pageview = (url: string) => {
  window.gtag('config', GA_TRACKING_ID as string, {
    page_path: url,
  });
};
